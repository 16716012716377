<template>
  <div class="lesson-comment" :class="{ child }">
    <div class="d-flex align-items-center mb-2">
      <h6 class="text-left mb-0 mr-3">
        {{ comment.name }}
      </h6>
      <small>{{ created_at }}</small>
    </div>

    <b-dropdown
      v-if="$user.user && comment.user_id === $user.user.id"
      variant="link"
      toggle-class="text-decoration-none"
      right
    >
      <template #button-content>
        <i class="icon-options text-secondary"></i>
      </template>
      <b-dropdown-item @click="editing = true">
        <i class="icon-edit"></i> Editar
      </b-dropdown-item>
      <b-dropdown-item @click="removing = true">
        <span class="text-danger"> <i class="icon-trash"></i> Excluir </span>
      </b-dropdown-item>
    </b-dropdown>

    <p class="mb-4">{{ comment.text }}</p>

    <div class="row">
      <div class="col text-left">
        <div class="d-flex align-items-center">
          <span v-if="comment.childers" class="count text-secondary mr-3">
            {{ comment.childers.length }}
            <i class="icon-talk text-secondary ml-1"></i>
          </span>
          <span class="count text-secondary">
            {{ comment.likes }} <i class="icon-like text-secondary ml-1"></i>
          </span>
        </div>
      </div>
      <div class="col text-right">
        <button
          class="btn-interaction text-primary mr-2"
          :class="{ 'text-primary': comment.liked }"
          @click="like"
        >
          <i :class="comment.liked ? 'icon-liked' : 'icon-like'" />
        </button>
        <button
          v-if="!child"
          class="btn-interaction text-primary"
          @click="answering = true"
        >
          <i class="icon-talk"></i> Comentar
        </button>
      </div>
    </div>

    <div v-for="(child, i) in comment.childers" :key="child.id" class="pl-4">
      <div
        class="mt-4"
        :class="{
          'divider-border-bottom':
            comment.childers.length > 1 && i < comment.childers.length - 1
        }"
      >
        <lesson-comment
          :comment="child"
          child
          @success-noreload="$emit('success-noreload')"
        />
      </div>
    </div>

    <send-comment
      v-if="answering"
      :lesson="$route.params.lesson"
      :parent="comment.id"
      @cancel="answering = false"
      @success="$emit('success')"
      @success-noreload="$emit('success-noreload')"
    />

    <b-modal v-model="editing" hide-header hide-footer centered>
      <form @submit.prevent="edit">
        <h5 class="text-center text-light mb-4">Editar comentário</h5>
        <form-group
          placeholder="Comentar..."
          id="text"
          v-model="form.text"
          :errors="errors.text"
          textarea
        />
        <div class="row">
          <div class="col-6">
            <button
              type="button"
              class="btn btn-block btn-secondary"
              @click="editing = false"
            >
              Cancelar
            </button>
          </div>
          <div class="col-6">
            <button type="submit" class="btn btn-block btn-primary">
              <loading :show="loading">Salvar</loading>
            </button>
          </div>
        </div>
      </form>
    </b-modal>

    <b-modal v-model="removing" hide-header hide-footer centered>
      <h5 class="text-center text-light mb-4">
        Tem certeza que deseja excluir?
      </h5>
      <div class="row">
        <div class="col-6">
          <button class="btn btn-block btn-secondary" @click="removing = false">
            Cancelar
          </button>
        </div>
        <div class="col-6">
          <button class="btn btn-block btn-primary" @click="remove">
            <loading :show="loading">Sim, excluir</loading>
          </button>
        </div>
      </div>
    </b-modal>
  </div>
</template>

<script>
import { formFields, timeFromNow } from "@/functions/helpers";
import SendComment from "./SendComment";

export default {
  name: "lesson-comment",
  components: { SendComment },
  props: {
    comment: {
      type: Object,
      default: () => ({})
    },
    child: {
      type: Boolean,
      default: false
    }
  },
  data() {
    return {
      answering: false,
      editing: false,
      removing: false,
      loading: false,

      ...formFields(["text"], { ...this.comment })
    };
  },
  computed: {
    created_at() {
      return timeFromNow(this.comment.created_at);
    }
  },
  methods: {
    like() {
      this.comment.liked = !this.comment.liked;
      this.comment.like = this.comment.liked
        ? this.comment.like + 1
        : this.comment.like - 1;
      this.$store
        .dispatch("forum/like", this.comment.id)
        .then(() => {
          this.$emit("success-noreload");
        })
        .catch(() => {
          this.comment.liked = !this.comment.liked;
        });
    },
    remove() {
      this.loading = true;
      this.$store
        .dispatch("forum/remove", this.comment.id)
        .then(() => {
          this.$message.success("Comentário excluído com sucesso");
          this.removing = false;
          this.$emit("success");
        })
        .finally(() => {
          this.loading = false;
        });
    },
    edit() {
      this.loading = true;
      this.$store
        .dispatch("forum/edit", {
          ...this.comment,
          ...this.form
        })
        .then(() => {
          this.$message.success("Comentário editado com sucesso");
          this.editing = false;
          this.$emit("success");
        })
        .finally(() => {
          this.loading = false;
        });
    }
  }
};
</script>

<style lang="scss" scoped>
.lesson-comment {
  position: relative;
  border-radius: 2px;

  .btn-interaction {
    border: none;
    background: transparent;
    padding: 0;
    display: inline-flex;
    align-items: center;

    i {
      font-size: 20px;
      margin-right: 8px;
    }

    img {
      max-height: 20px;
      margin-right: 8px;
    }
  }

  hr {
    border-color: black;
  }

  ::v-deep .dropdown {
    position: absolute;
    top: 2px;
    right: 0;
  }

  ::v-deep .dropdown-toggle {
    font-size: 24px;
    padding: 0px;

    &::after {
      display: none;
    }
  }

  .count {
    color: #d7d7d7;
    display: inline-flex;
    align-items: center;

    i {
      font-size: 24px;
    }
  }
}
</style>
