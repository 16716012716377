<template>
  <form @submit.prevent="submit" class="community-send-comment pt-3">
    <form-group
      placeholder="Escreva um comentário..."
      id="text"
      v-model="form.text"
      :errors="errors.text"
      textarea
    />
    <button class="btn btn-primary px-3 px-lg-5" :disabled="loading">
      <loading :show="loading">Publicar</loading>
    </button>
    <button
      class="btn btn-secondary ml-1 ml-lg-3"
      v-if="parent"
      @click="$emit('cancel')"
    >
      Cancelar
    </button>
  </form>
</template>

<script>
import { formFields } from "@/functions/helpers";

export default {
  props: {
    lesson: {
      type: [Number, String],
      default: null
    },
    parent: {
      type: [Number, String],
      default: null
    }
  },
  data() {
    return {
      loading: false,
      ...formFields(["text"])
    };
  },
  methods: {
    submit() {
      this.loading = true;
      const data = {
        ...this.form,
        lesson_id: this.lesson
      };
      if (this.parent) {
        data.parent_id = this.parent;
      }
      this.$store
        .dispatch("forum/add", data)
        .then(() => {
          this.$message.success("Seu comentário foi enviado e está em revisão");
          this.form.text = "";
          this.$emit("success");
        })
        .catch(error => {
          this.errors = error.response.data;
          if (this.errors.non_field_errors) {
            this.$message.error(this.errors.non_field_errors[0]);
          }
        })
        .finally(() => {
          this.loading = false;
        });
    }
  }
};
</script>

<style lang="scss" scoped>
.community-send-comment {
  ::v-deep .form-control {
    background: transparent !important;
    margin-bottom: 12px;
  }
}
</style>
