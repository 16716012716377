<template>
  <action-dispatcher
    action="lessons/getAll"
    :parameters="$route.params.id"
    class="view-lesson"
  >
    <div v-if="lesson" class="container-fluid px-lg-5 py-5 order-lg-1">
      <header class="d-flex">
        <router-link class="text-decoration-none" :to="{ name: 'module' }">
          <h6 class="mb-4">
            <i class="icon-chevron-left"></i> {{ lesson.module.name }}
          </h6>
        </router-link>
      </header>

      <div class="row">
        <div class="col-lg-8">
          <video-player
            v-if="lesson.link_media"
            ref="video-player"
            :lesson="lesson"
            @save-progress="saveProgress"
            @ended="goToNextLesson"
          />

          <div
            class="row flex-column flex-md-row align-items-center my-4 mx-n3"
          >
            <div class="px-3 flex-fill order-lg-1 w-mobile-100 my-4">
              <h4 class="font-weight-bold mb-0">{{ lesson.name }}</h4>
            </div>

            <div class="px-3 col-12 order-lg-3 my-4">
              <div class="lesson-description" v-html="lesson.description"></div>
            </div>

            <div class="px-3 order-lg-2 w-mobile-100">
              <button
                class="btn px-4 w-mobile-100"
                :class="{
                  'btn-outline-primary': !finishedLesson,
                  'btn-primary': finishedLesson
                }"
                @click="toggleFinished"
              >
                <loading :show="loadingFinish">
                  <div class="d-flex align-items-center justify-content-center">
                    <i v-if="finishedLesson" class="icon-check mr-2" />
                    <span v-if="finishedLesson">Concluído</span>
                    <span v-else>Marcar como concluído</span>
                  </div>
                </loading>
              </button>
            </div>
          </div>

          <h5
            v-if="lesson.extra_files && lesson.extra_files.length > 0"
            class="mt-4"
          >
            Materiais complementares
          </h5>
          <div v-for="(file, index) in lesson.extra_files" :key="'f' + index">
            <a :href="file.file" target="_blank" class="py-2">
              <i class="icon-download"></i> {{ file.name }}
            </a>
          </div>

          <h5
            v-if="lesson.extra_reading && lesson.extra_reading.length > 0"
            class="mt-4"
          >
            Leituras complementares
          </h5>
          <div
            v-for="(reading, index) in lesson.extra_reading"
            :key="'r' + index"
          >
            <a :href="reading.link" target="_blank" class="py-2">
              <strong>{{ reading.site_name }}</strong
              >: {{ reading.title }}
            </a>
          </div>
        </div>

        <div class="col-lg-4">
          <div class="lesson-list-title">Aulas do módulo</div>

          <lessons-list :lessons="lessons" />
        </div>

        <div v-if="lesson.comments_enabled" class="col-lg-8">
          <send-comment :lesson="$route.params.lesson" @success="reload" />

          <div class="d-flex align-items-center">
            <h5 class="flex-fill mt-5 mb-4 py-2">Comentários ({{ count }})</h5>
            <b-spinner v-if="silentLoading" variant="primary" small />
          </div>

          <action-dispatcher
            action="forum/getAll"
            :parameters="{ params }"
            ref="forumDispatcher"
            @success="forumSuccess"
          >
            <div
              v-for="comment in comments"
              :key="'c' + comment.id"
              :class="{ 'divider-border-bottom': comments.length > 1 }"
            >
              <comment
                class="my-3"
                :comment="comment"
                @success="reload"
                @success-noreload="silentReload"
              />
            </div>
            <h6 v-if="reviews.length" class="mt-4">Comentários em revisão</h6>
            <div
              v-for="comment in reviews"
              :key="'c' + comment.id"
              :class="{ 'divider-border-bottom': reviews.length > 1 }"
            >
              <comment
                class="my-3"
                :comment="comment"
                @success="reload"
                @success-noreload="silentReload"
              />
            </div>
          </action-dispatcher>

          <div class="text-center pt-4">
            <button
              v-if="comments.length > 0 && showLoadMore"
              class="btn btn-light px-5"
              @click="loadMore"
            >
              <loading :show="loadingMore">Carregar mais</loading>
            </button>
          </div>
        </div>
      </div>
    </div>
  </action-dispatcher>
</template>

<script>
import LessonsList from "../components/lesson/LessonsList";
import VideoPlayer from "../components/lesson/VideoPlayer";
import SendComment from "../components/lesson/SendComment";
import Comment from "../components/lesson/Comment";
import { mapState } from "vuex";
import get from "lodash/get";

export default {
  components: {
    LessonsList,
    VideoPlayer,
    SendComment,
    Comment
  },
  data() {
    return {
      loadingFinish: false,
      silentLoading: false,
      tab: "chapters",
      params: {
        id: this.$route.params.lesson,
        limit: 10,
        offset: 0
      },
      loadingMore: false,
      showLoadMore: false
    };
  },
  computed: {
    ...mapState("lessons", ["lessons"]),
    ...mapState("forum", ["comments", "reviews", "count"]),
    moduleParams() {
      return {
        id: get(this.module, "id")
      };
    },
    lesson() {
      if (!this.lessons) {
        return null;
      }
      return this.lessons.find(
        lesson => lesson.id === this.$route.params.lesson
      );
    },
    nextLesson() {
      for (let i = 0; i < this.lessons.length; i++) {
        if (this.lessons[i].id === this.$route.params.lesson) {
          return this.lessons[i + 1];
        }
      }
      return null;
    },
    finishedLesson() {
      return (
        this.lesson && this.lesson.progress && this.lesson.progress.finished_at
      );
    },
    chapters() {
      return get(this.lesson, "chapters", []).sort((a, b) => {
        if (a.time > b.time) {
          return 1;
        }
        if (b.time > a.time) {
          return -1;
        }
        return 0;
      });
    }
  },
  mounted() {
    this.resetComments();
  },
  methods: {
    goToNextLesson() {
      if (this.nextLesson) {
        this.$router.push({
          params: { lesson: this.nextLesson.id }
        });
      }
    },
    resetComments() {
      this.showLoadMore = true;
      this.params.offset = 0;
      this.$store.commit("forum/setComments", []);
    },
    reload() {
      this.resetComments();
      this.$refs.forumDispatcher.dispatch();
    },
    silentReload() {
      this.silentLoading = true;
      this.params.offset = 0;
      this.$store
        .dispatch("forum/getAll", {
          params: this.params,
          replaceComments: true
        })
        .then()
        .finally(() => {
          this.silentLoading = false;
        });
    },
    forumSuccess(data) {
      this.showLoadMore = !!data?.next;
    },
    loadMore() {
      this.loadingMore = true;
      this.params.offset += this.params.limit;
      this.$store
        .dispatch("forum/getAll", { params: this.params })
        .then(data => {
          this.showLoadMore = !!data?.next;
        })
        .finally(() => {
          this.loadingMore = false;
        });
    },
    clickChapter(chapter) {
      const timeArr = chapter.time.split(":").map(str => parseInt(str));
      this.$refs["video-player"].player.currentTime =
        timeArr[0] * 60 * 60 + timeArr[1] * 60 + timeArr[2];
    },
    toggleFinished() {
      this.saveProgress({ finished: !this.finishedLesson, duration: 0 }, true);
    },
    saveProgress({ finished, duration }, refresh = false) {
      if (refresh) {
        this.loadingFinish = true;
      }
      this.$store
        .dispatch("modules/setProgress", {
          lesson_id: this.lesson.id,
          stopped_at: parseInt(duration),
          finished_at: finished
        })
        .then(() => {
          if (refresh) {
            setTimeout(() => {
              this.$store
                .dispatch("lessons/getAll", this.$route.params.id)
                .finally(() => {
                  this.loadingFinish = false;
                });
            }, 1000);
          }
        });
    }
  }
};
</script>

<style lang="scss" scoped>
.view-lesson {
  min-height: calc(100vh - 56px);

  @media screen and (max-width: 991px) {
    flex-direction: column-reverse;
  }

  iframe {
    height: 500px;
  }

  .module-card {
    .card-img-top {
      height: 0;
      padding-bottom: 55%;
      background-size: cover;
      background-position-x: center;
      background-position-y: center;
    }
  }

  a {
    line-height: 34px;
  }

  button {
    i {
      font-size: 22px;
    }
  }

  .list-group {
    .list-group-item {
      display: flex;
      align-items: center;
      justify-content: space-between;
      background: #576574;
      color: rgba(255, 255, 255, 0.9);

      &:hover {
        background: rgb(77, 91, 106);
      }
    }
  }

  .lesson-list-title {
    font-size: 16px;
    font-weight: bold;
    line-height: 24px;
    margin-bottom: 16px;
  }

  .scroll {
    @media screen and (max-width: 991px) {
      padding-top: 4px;
    }

    @media screen and (min-width: 992px) {
      position: absolute;
      top: 40px;
      left: 15px;
      right: 15px;
      bottom: 0;
      overflow: scroll;
    }
  }

  .w-mobile-100 {
    @media screen and (max-width: 991px) {
      width: 100%;
    }
  }
}
</style>
